<template>
    <div>
        <div class="row">
            <div class="col-3 mx-auto text-center">
                <h3>Planos e Preços</h3>
            </div>
        </div>
        <div class="row justify-content-center mt-3" v-if="plans.length > 0">
            <div class="col-sm-4">
                <div class="card">
                    <div class="card-body">
                        <form method="POST" :action="checkoutUrl">
                            <input type="hidden" :value="plans[0].stripeId" name="planId">
                            <div class="description mb-3">
                                <h3>Plano Grátis</h3>
                                <hr/>
                                <h5 class="mt-2 pt-2">Grátis</h5>
                                <hr/>
<!--                                <div class="mt-2 pt-2">Uso não comercial</div>
                                <hr/>-->
                                <div class="mt-2 pt-2"><b>{{plans[0].maxRequestsMes}}</b> Pedidos / mês</div>
                                <hr/>
                                <div class="mt-2 pt-2"><b>{{plans[0].maxRequestsMin}}</b> Pedidos / minuto</div>
                                <hr/>
                                <div class="mt-2 pt-2">
                                    &nbsp;
                                </div>
                            </div>
                            <hr/>
                            <button v-if="subscription.planId !== null && subscription.planId !== plans[0].stripeId"
                                    class="btn btn-primary">Alterar</button>
                            <button v-else class="btn btn-outline-primary" disabled>Plano actual</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card">
                    <div class="card-body">
                        <form method="POST" :action="checkoutUrl">
                            <input type="hidden" :value="plans[1].stripeId" name="planId">
                            <div class="description mb-3">
                                <h3>Plano Business</h3>
                                <hr/>
                                <h5 class="mt-2 pt-2">€20 / mês + IVA</h5>
                                <hr/>
                                <div class="mt-2 pt-2"><b>{{new Intl.NumberFormat('pt-PT').format(plans[1].maxRequestsMes)}}</b> pedidos / mês</div>
                                <hr/>
                                <div class="mt-2 pt-2"><b>{{new Intl.NumberFormat('pt-PT').format(plans[1].maxRequestsMin)}}</b> pedidos / minuto</div>
                                <hr/>
                                <div class="mt-2 pt-2">
                                    <i class="fa fa-check-circle" style="color: green;"></i> Suporte prioritário por email
                                </div>
                            </div>
                            <hr/>
                            <button v-if="subscription.planId !== plans[1].stripeId" class="btn btn-primary">Alterar</button>
                            <button v-else class="btn btn-outline-primary" disabled>Plano actual</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-3">
                <a :href="createPortalSessionUrl" class="btn btn-primary"><i class="fa fa-euro-sign"></i> Gerir plano</a>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <h4>Porquê optar por um plano pago?</h4>

                <p>
                    Para continuar a oferecer uma API gratuita, desenvolver e manter este serviço deste tipo envolve custos. Estes incluem servidores, monitorização e manutenção contínua.
                    <br/>Ao escolher um plano pago, está a ajudar-nos a cobrir esses custos, garantindo a continuidade e melhoria do serviço.
                </p>

                <p>
                    <b>Suporte prioritário</b>
                    - Utilizadores de planos pagos têm prioridade no suporte por email, para ajudar em questões técnicas ou de uso.
                </p>

                <p>
                    <b>Limites mais altos</b>
                    - Utilizadores de planos pagos têm limites mais altos de pedidos por minuto e por mês.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            plans: {},
            subscription: {
                planId: null,
            },

            loading: false
        };
    },
    mounted() {
        this.loadSubscriptionData();
    },
    computed: {
        checkoutUrl() {
            // return jsRoutes.controllers.Account.openCheckout().url;
            return jsRoutes.controllers.AccountController.createPortalSessionPlanUpgrade().url;
        },
        createPortalSessionUrl() {
            return jsRoutes.controllers.AccountController.createPortalSession().url;
        }
    },
    methods: {
        loadSubscriptionData() {
            const self = this;
            let url = jsRoutes.controllers.AccountController.loadSubscriptionData().url;

            axios.get(url).then(function (response) {
                // set data
                self.subscription.planId = response.data.planId;
                self.plans = response.data.planos;
            }).catch(function (error) {
                console.log("error: ", error);
                // handle error
                // self.handleAjaxError(error);
            });
        },
        formatNumber(number) {
            return new Intl.NumberFormat().format(number)
        }
    },
};
</script>


<style scoped>

</style>
