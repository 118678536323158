<template>
    <div>
        <form class="form-inline" @submit.stop.prevent="onSubmit">
            <label class="mr-2">Pesquisar Código Postal</label>
            <input class="form-control"
                   ref="cp1"
                   name="cp1"
                   v-model="cp1"
                   type="number"
                   placeholder="0000"
                   maxlength="4"
                   min="0"
                   max="9999"
                   required
                   @input="checkCp1"
                   @keyup.enter="onSubmit"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
            <label class="ml-1 mr-1">-</label>
            <input class="form-control"
                   ref="cp2"
                   name="cp2"
                   v-model="cp2"
                   type="number"
                   placeholder="000"
                   maxlength="3"
                   min="0"
                   max="999"
                   required
                   @keyup.enter="onSubmit"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
            <button type="submit" class="btn btn-primary ml-2" title="Pesquisar">
                <i class="fas fa-search"></i>
            </button>
        </form>
    </div>
</template>
<style scoped>

</style>
<script>
export default {
    components: {},
    computed: {},
    data() {
        return {
            cp1: "",
            cp2: "",
        }
    },
    mounted() {
    },
    methods: {
        onSubmit() {
            window.location.href = jsRoutes.controllers.CpController.getCatchAllPage(this.cp1 + "-" + this.cp2).url;
        },
        checkCp1() {
            if (this.cp1.length === 4) {
                this.$refs.cp2.focus();
            }
            // console.log("checkCp1 => " + this.cp1)
        }
    }
};
</script>
