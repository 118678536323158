<template>
    <div>
            <form @submit.prevent="sendRequest">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text"
                           v-model="email"
                           id="email"
                           :class="{'is-invalid': $v.email.$error}"
                           class="form-control">
                    <span class="invalid-feedback"
                          v-show="!$v.email.required">
                            Obrigatório!
                    </span>
                    <span class="invalid-feedback"
                          v-show="!$v.email.email">
                            Email inválido!
                    </span>
                </div>
                <v-button class="btn btn-primary"
                          :loading="loading"
                        type="submit">
                    Criar conta
                </v-button>
                <a class="btn btn-link" :href="loginUrl">
                    Já tem conta? Ir para login
                </a>
                <p v-if="success" class="alert alert-success mt-3">
                    API Key gerada com sucesso! Consulte o seu email.
                </p>

<!--                <div class="row mb-3">
                    <label for="email_field"
                           class="col-form-label col-auto">
                        Email
                    </label>
                    <div class="col">
                        <input type="text"
                               v-model="email"
                               id="email_field"
                               :class="{'is-invalid': $v.email.$error}"
                               class="form-control">
                        <span class="invalid-feedback"
                              v-show="!$v.email.required">
                            Obrigatório!
                        </span>
                        <span class="invalid-feedback"
                              v-show="!$v.email.email">
                            Formato de email inválido!
                        </span>
                    </div>

                </div>-->

<!--
                <div class="row justify-content-end">
                    <button class="btn btn-secondary mr-2"
                            @click="cleanData"
                            type="button">
                        Cancelar
                    </button>
                    <button class="btn btn-primary"
                            type="submit">
                        Enviar
                    </button>
                </div>
-->

            </form>
    </div>
</template>

<script>
/**
 *
 * Created by Filipe - 31/03/2023
 *
 */
import {email, required} from "vuelidate/lib/validators";
import axios from "axios";
import VButton from "@/components/ui/VButton.vue";


export default {
    name: "ApiSignupPage",
    components: {
		VButton
    },
    data() {
        return {
            email: '',
            loading: false,
			success: false,
        }
    },
    validations() {
        return {
            email: {
                required,
                email
            }
        }
    },
    computed: {
        loginUrl() {
            return jsRoutes.controllers.AccountController.login().url;
        }
    },
    methods: {

        sendRequest() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                //console.error('Validation error!');
                return;
            }
			this.loading = true;
            const url = jsRoutes.controllers.AccountController.signup().url;
            axios.post(url, {email: this.email})
                .then((/*response*/) => {
                    // console.debug('response ', response);
                    this.cleanData();
					this.success = true;
                })
                .catch(error => {
                    console.error('error ', error);
                })
				.finally(() => {
					this.loading = false;
				})
        },

        cleanData() {
            this.email = '';
            this.$v.$reset();
        }

    },
}
</script>

<style scoped>

</style>
