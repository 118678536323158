import Vue from "vue";

import "jquery";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css'

import VTooltip from "v-tooltip";
import Vuelidate from 'vuelidate';

import CpForm from "@/views/CpForm";
import ApiSignupPage from "@/views/ApiSignupPage";
import SubscriptionComponent from "@/views/SubscriptionComponent.vue";

Vue.use(Vuelidate);
Vue.use(VTooltip);

// eslint-disable-next-line no-unused-vars
window.copyToClipboard = async function copyToClipboard(text)  {
  try {
    await navigator.clipboard.writeText(text);
    // console.log('Content copied to clipboard');
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

/*
if (document.getElementById("mainNavBar")) {
  new Vue({
    store,
    render: (h) => h(MainNavBar),
  }).$mount("#mainNavBar");
}
*/

if (document.getElementById("cpForm")) {
  new Vue({
    //store,
    render: (h) => h(CpForm),
  }).$mount("#cpForm");
}

if (document.getElementById("api_signup_page")) {
  new Vue({
    //store,
    render: (h) => h(ApiSignupPage),
  }).$mount("#api_signup_page");
}

if (document.getElementById("subscriptionComp")) {
  new Vue({
    //store,
    render: (h) => h(SubscriptionComponent),
  }).$mount("#subscriptionComp");
}

/*
if (document.getElementById("homePage")) {
  new Vue({
    //store,
    render: (h) => h(HomePage),
  }).$mount("#homePage");
}
*/

/*if (document.getElementById("distritosList")) {
  new Vue({
    store,
    render: (h) => h(DistritosListPage),
  }).$mount("#distritosList");
}

if (document.getElementById("distritoPage")) {
  new Vue({
    store,
    render: (h) => h(DistritoPage),
  }).$mount("#distritoPage");
}

if (document.getElementById("concelhosListPage")) {
  new Vue({
    store,
    render: (h) => h(ConcelhosListPage),
  }).$mount("#concelhosListPage");
}

if (document.getElementById("concelhoPage")) {
  new Vue({
    store,
    render: (h) => h(ConcelhoPage),
  }).$mount("#concelhoPage");
}*/

Vue.config.productionTip = false;

/*
import Join from "./Join.vue";
if(document.getElementById("joinContainer")) {
    new Vue({
        i18n,
        render: h => h(Join)
    }).$mount("#joinContainer");
}
*/
